import React, { useEffect, useRef } from "react";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateCompany = props => {

    const{auth,user} = React.useContext(UserContext)
    const[role, setUserRole] =  React.useState('admin');
    const[name, setName] =  React.useState('');
    const[companyName, setCompanyName] =  React.useState('');
    
    const[email, setEmail] =  React.useState('');
    const[password, setPassword] =  React.useState('');
    
    const[phone, setPhone] =  React.useState('');
    const[referral, setReferral] =  React.useState('');
    
    const [cPrefix, setCPrefix] =  React.useState('');
    const[registrationLink, setRegistrationLink] =  React.useState('');
    
  
    React.useEffect( () => {
    
      if (auth != null) {
        if (!auth) {
          window.location.href="/login"
        }else{
            // 
        }
      }
    }, [auth])

    const roleChange = async (role) => {
        setUserRole(role);
    }

    const nameChange = async (name) => {
        setName(name);
    }

    const CompanyNameChange = async (company) => {
      setCompanyName(company);
    }

    const emailChange = async (email) => {
        setEmail(email);
    }

    const passwordChange = async (password) => {
        setPassword(password);
    }

    const phoneChange = async (phone) => {
      setPhone(phone);
  }

    const referralChange = async (referral) => {
        setReferral(referral);
    }

    const registrationLinkChange = async (registration) => {
      setRegistrationLink(registration);
    }

    const generateTempPassword = async () => {
      var newPassword = await passwordGenerator()
      return setPassword(newPassword)
    }

    const cPrefixChange = async (prefix) => {
      setCPrefix(prefix)
    }

    const passwordGenerator = async() => {
      const length = 8; // Set the desired length of the temporary password
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@!&_$'; // Define the character set to use
      let password = '';
      for (let i = 0; i < length; i++) {
        password += charset[Math.floor(Math.random() * charset.length)]; // Pick a random character from the character set
      }
      return password;
    }

    const onSubmitAdmin = async () => {
      // const company_name = req.body.planid;
      // const director_name = req.body.productid;
      // const email = req.body.email;
      // const phone = req.body.phone;
      // const temp_password = "true";
      // const password = req.body.password;
      // const reg_link = req.body.reg_link;
      // const c_prefix = req.body.c_prefix;
      // const status = "pending";

        if(companyName === '')
        {
            return toast.error('Enter Company Name', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        if(name === '')
        {
            return toast.error('Enter Name', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        if(email === '')
        {
            return toast.error('Enter Email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        if(password === '')
        {
            return toast.error('Enter Password', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        if(phone === '')
        {
            return toast.error('Enter Phone Number', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

              // const company_name = req.body.planid;
      // const director_name = req.body.productid;
      // const email = req.body.email;
      // const phone = req.body.phone;
      // const temp_password = "true";
      // const password = req.body.password;
      // const reg_link = req.body.reg_link;
      // const c_prefix = req.body.c_prefix;
      // const status = "pending";
        var formObj = {
          company_name: companyName,
          director_name: name,
          email: email,
          phone: phone,
          password: password,
          reg_link: registrationLink,
          c_prefix: cPrefix,
        }

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-new-company`,formObj).then((res)=>{

            if(res.data.status == 200)
            {
                return toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }else{
                return toast.error(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }

        })
    }


  //meta title
  document.title = "New Admin | C1 FS";

  return (
    <React.Fragment>
            <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="New Company" />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Company</CardTitle>

                  <Form>
                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Director First name And Last Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="First And Last Name"
                        value={name}
                        onChange={(e) => {nameChange(e.target.value)}}
                      />
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Company Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => {CompanyNameChange(e.target.value)}}
                      />
                    </div>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Enter Admin Email ID"
                            value={email}
                            onChange={(e) => {emailChange(e.target.value)}}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Phone (Need to start with 27)</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder="Enter Phone Number"
                            value={phone}
                            onChange={(e) => {phoneChange(e.target.value)}}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                    <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Tempory Password</Label>
                          <Input
                            type="text"
                            disabled
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder="Enter Your Password"
                            value={password}
                            // onChange={(e) => {passwordChange(e.target.value)}}
                          />
                          <button type="button" className="btn btn-primary mt-2" onClick={generateTempPassword}>Generate Tempory Password</button>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">

                          
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Registration Link</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Enter Registration Link"
                            value={registrationLink}
                            onChange={(e) => {registrationLinkChange(e.target.value)}}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Company Prefix (Example C1W) <small className="text-danger">Please ensure this is unique</small></Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder="Enter Prefix"
                            value={cPrefix}
                            onChange={(e) => {cPrefixChange(e.target.value)}}
                          />
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <a onClick={onSubmitAdmin} className="btn btn-primary w-md">
                        Submit
                      </a>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
        </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  );
};

export default CreateCompany;
