import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Form,
  Label,
  Select
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../../Dashboard/LatestTranactionCol";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
function ApprovedWithdraw() {
    
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('Approved Withdraws');

    const[transactions, setTransactions] =  React.useState([]);

    const[selectedDate, setSelectedDate] =  React.useState(null);
    const[dates, setDates] =  React.useState([]);

    const[loading, setLoading] =  React.useState(false);
    
    const[allUsers, setAllUsers] =  React.useState([]);

    const columns = useMemo(
        () => [
          {
            Header: "Userid",
            accessor: "userid",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Name",
            accessor: "name",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Email",
            accessor: "email",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Currency",
            accessor: "currency",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value == '1' ? 'USDT' : cellProps.value == '2' ? 'ZAR' : 'BTC'}
                </p>
              );
            },
          },
          {
            Header: "Amount",
            accessor: "amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              let formattedAmount = parseFloat(cellProps.value);
          
              if (cellProps.row.original.currency === '1' || cellProps.row.original.currency === '2') {
                formattedAmount = formattedAmount.toFixed(2);
              } else if (cellProps.row.original.currency === '3') {
                formattedAmount = formattedAmount.toFixed(6);
              }
          
              return <span>{formattedAmount}</span>;
            },
          },
          {
            Header: "To Wallet",
            accessor: "w_address",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Date",
            accessor: "createdAt",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          }
        ],
        []
      );

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            
            getUserDepositDates()
          }
        }
      }, [auth])


      const getUserDeposits = async (newDate) => {
        
        var dateObj = {
          date: newDate
        }
        await axios.post(process.env.REACT_APP_BASE + "api/approved-withdrawals", dateObj).then(async(res)=>{
            console.log('all withdrawals ', res.data)
            if(res.data.status === 200)
            {
              setLoading(false)
                setTransactions(res.data.withdrawals)
            }else{
                setTransactions([])
            }
          
         
        });
      }

      const getUserDepositDates = async() => {
        
        await axios.get(process.env.REACT_APP_BASE + "api/approved-withdrawals-dates").then(async(res)=>{
          console.log('all dates ', res.data)
          if(res.data.status === 200)
          {
            setDates(res.data.dates)
          }
      
      });
      }

      const onChangeDate = async(newDate) => {
        setLoading(true)
        setSelectedDate(newDate)
        getUserDeposits(newDate)
      }

  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />

<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="Transactions" breadcrumbItem="Transactions" />

<Row>
  <Form>
    <Col md={12}>
        <Label>Select Deposit date:</Label>
        <select value={selectedDate} onChange={(e) => onChangeDate(e.target.value)} className="form-control ">
                <option value="">Select Date</option>
                {dates.map((date,key) => (
                   <option key={key} value={date}>{date}</option>
                ))}
               
            </select>
    </Col>
  </Form>
  <Col md="12" sm="12">
    {!loading ?

transactions && transactions.length > 0 ?
        <UserSingleTable tableData={transactions} columns={columns} title={title} />

        : "No Data Available"

        : "Loading..."}
        </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ApprovedWithdraw

