import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";


import { UserContext } from "../../UserContext";
import axios from 'axios'

const SidebarContent = props => {
  const{auth,user} = React.useContext(UserContext)
  const[role, setUserRole] =  React.useState([]);

  React.useEffect( () => {
  
    if (auth != null) {
      if (!auth) {
        window.location.href="/login"
      }else{
        setUserRole(user.user.u_role)
      }
    }
  }, [auth])

  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/#" >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Information")}</li>


            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/users">{props.t("My Users")}</Link>
                </li>
                {role === "super"?<div>
                <li>
                  <Link to="/pending-kyc">{props.t("Pending KYC")}</Link>
                </li>
                </div>
                : ''}
              </ul>
            </li>

            <li>
            <Link to="/#" className="has-arrow">
              <i className="bx bx-store"></i>
              <span>{props.t("Notifications")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/view-tasks">{props.t("My Tasks")}</Link>
              </li>

              {/* <li>
                <Link to="/user-notifications">{props.t("User Notifications")}</Link>
              </li> */}

            </ul>
            </li>

            {role !== "agent"?
            <li>
            <Link to="/#" className="has-arrow">
              <i className="bx bx-store"></i>
              <span>{props.t("User Reports")}</span>
            </Link>
            <ul className="sub-menu">
       
              <li>
                <Link to="/report-list">{props.t("Reports")}</Link>
              </li>

            </ul>
            </li> 
            : ""}

            {role === "admin" ?
            <div>
              <li>
            <Link to="/#" className="has-arrow">
              <i className="bx bx-store"></i>
              <span>{props.t("Admin")}</span>
            </Link>
            <ul className="sub-menu">
     
              <li>
                <Link to="/create-admin">{props.t("Create Admin")}</Link>
              </li>

              
            </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Agent Proceeds")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/my-agents">{props.t("My Agents")}</Link>
                </li>

                <li>
                  <Link to="/referral-history">{props.t("Agent Proceed History")}</Link>
                </li>

                <li>
                  <Link to="/onboarding-fee">{props.t("Agent Onboardings")}</Link>
                </li>
              
              </ul>
            </li> 
            
            </div>
            
            : ''
            }

            {role === "super"?
            <div>
            <li>
            <Link to="/#" className="has-arrow">
              <i className="bx bx-store"></i>
              <span>{props.t("Transactions")}</span>
            </Link>
            <ul className="sub-menu">
                <li>
                  <Link to="/pending-deposit">{props.t("Pending Deposits")}</Link>
                </li>

                <li>
                  <Link to="/approved-deposit">{props.t("Approved Deposits")}</Link>
                </li>

                <li>
                  <Link to="/pending-withdraw">{props.t("Pending Withdraws")}</Link>
                </li>

                <li>
                  <Link to="/capital-pending-withdraw">{props.t("Capital Pending Withdraws")}</Link>
                </li>

                <li>
                  <Link to="/pending-exchange">{props.t("Pending Exchanges")}</Link>
                </li>

                <li>
                  <Link to="/pending-bank-confirmation">{props.t("Pending Bank Confirmation")}</Link>
                </li>

                <li>
                  <Link to="/all-withdrawals">{props.t("All Withdrawals")}</Link>
                </li>

                <li>
                  <Link to="/pending-user-loans">{props.t("Pending Loans")}</Link>
                </li>

                <li>
                  <Link to="/pending-ltv-loans">{props.t("Pending LTV Loans")}</Link>
                </li>
              
                </ul>
            </li>

             <li>
             {/* <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Reports")}</span>
              </Link></li> */}
              <ul className="sub-menu">
                {/* <li>
                  <Link to="/view-reports">{props.t("Reports")}</Link>
                </li> */}
              
              </ul>
            </li>

            <li>
            <Link to="/#" className="has-arrow">
              <i className="bx bx-store"></i>
              <span>{props.t("Admin")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/admin-users">{props.t("All Admin")}</Link>
              </li>
              <li>
                <Link to="/create-admin">{props.t("Create Admin")}</Link>
              </li>
              <li>
                <Link to="/assign-admin">{props.t("Assign Admin")}</Link>
              </li>

            </ul>
            </li>

            {/* Still in Sandbox
            <li>
            <Link to="/#" className="has-arrow">
              <i className="bx bx-store"></i>
              <span>{props.t("Products")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/create-products">{props.t("Create Products")}</Link>
              </li>
              <li>
                <Link to="/view-products">{props.t("View Products")}</Link>
              </li>

            </ul>
            </li> */}

            <li>
            <Link to="/#" className="has-arrow">
              <i className="bx bx-store"></i>
              <span>{props.t("Company")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/create-company">{props.t("Create Company")}</Link>
              </li>

            </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Exchanges")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/exchange-history">{props.t("Exchange History")}</Link>
                </li>

              </ul>
            </li>

            
            </div>
            
            : ''}


<li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">

                <li>
                  <Link to="/admin-password-reset">{props.t("Password Reset")}</Link>
                </li>

    
              </ul>
            </li>

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
