import React, { useEffect, useState, useMemo } from "react";

import { UserContext } from "../../../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

import PropTypes from 'prop-types'

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "../../../Dashboard/UserSingleTable";
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "../../../Dashboard/LatestTranactionCol";

import {
    Card,
    CardHeader,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner,
    Form
  } from "reactstrap";
import withdrawals from "pages/SinglePages/withdrawals";

// import io from "socket.io-client"


function SalesReportContainer({displayAllSelection, companyID=''}) {

  // const socket = io('https://c1fs-node-server-66140b83eb8c.herokuapp.com', {});
  // window.socket = socket;

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');

    const[dateOptions, setDateOptions] =  React.useState([]);
    const[showLoadingSpinner, setShowLoadingSpinner] =  React.useState(true);

    
    const[foundData, setFoundData] =  React.useState(false);
    const[loadData, setLoadData] =  React.useState([]);
    const[newSales, setNewSales] =  React.useState(0);
    const[inTrade, setIntrade] =  React.useState(0);
    const[newMonthAmount, setNewMonthAmount] =  React.useState(0);
    const[NextQAmount, setNextQAmount] =  React.useState(0);
    const[ExpireQAmount, setExpireQAmount] =  React.useState(0);
    const[nextQStarting, setNextQStarting] =  React.useState(0);
    
    
    const[dropdownDate, setDropdownDate] =  React.useState('');
    const[reportHeader, setReportHeader] =  React.useState('');
    const[userEmail, setUserEmail] =  React.useState('');
    const[selectedProductid, setSelectedProductid] =  React.useState('');
    const[selectedProductName, setSelectedProductName] =  React.useState('');
    
    
    
    

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
           if(user)
           {
            console.log("the company to find is ", companyID)
            getTransactionDates(companyID)
            setUserid(user.user.userid);
            setUserEmail(user.user.email);
           }
    
          }
        }
      }, [auth])

    const getTransactionDates = async(companyID) => {
      var obj = {
        companyID: companyID
      }
        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-productsale-dates", obj).then(async res2 =>{
            console.log(res2.data)
            if(res2.data.status == 200)
            {
                await onTransactionDateChanged('all')
                setDateOptions(res2.data.dates)
                setShowLoadingSpinner(false)
            }
        })
      }

    const onTransactionDateChanged = async(date) => {
        // return false;
        if(date =='all')
        {
          setLoadData([])
          setIntrade(0)
          setNewSales(0)
          setNewMonthAmount(0)
          
          setNextQAmount(0)
          setExpireQAmount(0)
          setNextQStarting(0)

          setFoundData(false)
          setDropdownDate(date)
          return false;
        } 

        // await getTransactionsForDate(date)
        console.log(date)
        setDropdownDate(date)
        setReportHeader(date + "-product-sales")
    }

    const getTransactionsForDate = async(date, productid) => {
      // return false;
        setShowLoadingSpinner(true)
        console.log("date is " + date)
        var transactionObj = {
            dates: date,
            companyID: companyID,
            productid: productid
          }

        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-date-product-sales", transactionObj).then(async res2 =>{
            console.log(res2.data)
            if(res2.data.status == 200)
            {
              setLoadData(res2.data.transactions)
              setFoundData(true)
              setShowLoadingSpinner(false)
              var formattedAmount = ""
              if(res2.data.totalSalesAmount && res2.data.totalSalesAmount !== null)
              {
                formattedAmount = res2.data.totalSalesAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                });
              }

              var formattedNewSalesAmount = ""
              if(res2.data.newSalesAmount && res2.data.newSalesAmount !== null)
              {
              formattedNewSalesAmount = res2.data.newSalesAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              });
            }

            var formattedNewMonthAmount = ""
            if(res2.data.nextMonthStarting && res2.data.nextMonthStarting !== null)
            {
              formattedNewMonthAmount = res2.data.nextMonthStarting.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              });
            }

            var formattedNextQAmount = ""
            if(res2.data.nextQSalesAmount && res2.data.nextQSalesAmount !== null)
            {
              formattedNextQAmount = res2.data.nextQSalesAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              });
            }

            var formattedExpireQAmount = ""
            if(res2.data.nextQExpireAmount && res2.data.nextQExpireAmount !== null)
            {
              formattedExpireQAmount = res2.data.nextQExpireAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              });
            }

            var formattedNextQStartingAmount = ""
            if(formattedExpireQAmount && formattedExpireQAmount !== null)
            {
              var newQstartingAmount = res2.data.newSalesAmount + res2.data.nextQSalesAmount - res2.data.nextQExpireAmount + res2.data.totalSalesAmount
              formattedNextQStartingAmount = newQstartingAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              });
            }


              setIntrade(formattedAmount)
              setNewSales(formattedNewSalesAmount)
              setNewMonthAmount(formattedNewMonthAmount)

              setNextQAmount(formattedNextQAmount)
              setExpireQAmount(formattedExpireQAmount)
              setNextQStarting(formattedNextQStartingAmount)
 
                return true;
            }else{
              setLoadData([])
              setIntrade(0)
              setNewSales(0)
              setNewMonthAmount(0)

              setNextQAmount(0)
              setExpireQAmount(0)
              setNextQStarting(0)

              setFoundData(false)
                setShowLoadingSpinner(false)
                return false;
            }
        })

        setShowLoadingSpinner(false)
    }

    const columns = useMemo(
      () => [
        {
          Header: "Userid",
          accessor: "userid",
          filterable: true,
          disableFilters: true,
          Cell: cellProps => {
            return <OrderId {...cellProps} />;
          },
        },
        {
          Header: "User",
          accessor: "names",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <BillingName {...cellProps} />;
          },
        },
        {
          Header: "Planid",
          accessor: "planid",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <Date {...cellProps} />;
          },
        },
        {
          Header: "Amount",
          accessor: "amount",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <Total {...cellProps} />;
          },
        },
        {
          Header: "Invest Date",
          accessor: "invest_date",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <Total {...cellProps} />;
          },
        },
        {
          Header: "Start Date",
          accessor: "start_date",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <Total {...cellProps} />;
          },
        },
        {
          Header: "Expire Date",
          accessor: "expire_date",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <Total {...cellProps} />;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return cellProps.value === '1' ? <p>Active</p> : cellProps.value === '2' ? <p>Declined</p> : cellProps.value === '0' ? <p>Pending</p> : <p>Closed</p>;
          },
        },
      ],
      []
    );

    const DownloadCSVClick = async() => {
      console.log("We need to get download")
      await getTransactionReport()
      // const fileName = 'my_data.csv';
    
      // return (
      //   <CSVLink data={data} filename={fileName}>
      //     Download CSV
      //   </CSVLink>
      // );
    }

    const getTransactionReport = async() => {
      return false;
      socket.off('get transactions', getTransactionsNew)

      toast.success('Generating Report, an Email will be sent!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      getTransactions(userEmail)
    }

    const getTransactions = async(newemail) => {
      return false;
      socket.on('get transactions', getTransactionsNew(newemail));
      socket.emit('get transactions', {email: newemail});
    }

    const getTransactionsNew = async(email) => {
      return email
    }

    const onProductChange = async(product) => {
      console.log(product)
      setSelectedProductid(product)
      const getSelectedProductName = productOptions.find(option => option.id === product);
      const productName = getSelectedProductName.name; 
      setSelectedProductName(productName)
      await getTransactionsForDate(dropdownDate, product)
      
    }

    var productOptions = [
      {
        id: '36',
        name: 'Synthetic Assets'
      },
    ]


  return (
    <div>

      <h3  className="text-center">Portfolio Performance Report</h3>
        <Row>
            <Col md={2}>
                <Button onClick={(e) => {displayAllSelection()}} className={"border-primary bg-primary text-dark"} >Go Back</Button>

            </Col>
        </Row>
        
        {showLoadingSpinner ?
     
                <Spinner type="grow" className="ms-2" color="secondary" />
                :
        <div >
        <Row className="mt-4">
            <Col md={8}>
                <label className="">Select Sales Month</label>
                <select onChange={(e) => {onTransactionDateChanged(e.target.value)}} value={dropdownDate} className="form-control" id="referrals">
                    <option value="all">Select Date</option>
                    {typeof dateOptions !== 'undefined' && dateOptions.length > 0 ?
                    dateOptions.map((date, key) => (
                        <option key={key} value={date}>{date}</option>
                    ))
                    : ''}
                </select>
            </Col>

            <Col md={4}>
              {typeof loadData === undefined || loadData.length <= 0?
                // <Button className="btn btn-rounded btn-danger mt-4" onClick={(e) => {DownloadCSVClick()}}>Download CSV Report</Button>
                ""
                :
                <CSVLink
                    className="btn btn-rounded btn-danger mt-4"
                    data={loadData}
                    filename={reportHeader}
                >
                    Download CSV
                </CSVLink>
              }
            </Col>
            
        </Row>

        <Row className="mt-4">
            <Col md={8}>
                <label className="">Products</label>
                <select onChange={(e) => {onProductChange(e.target.value)}} value={selectedProductid} className="form-control" id="referrals">
                    <option value="all">Select Product</option>
                    {typeof productOptions !== 'undefined' && productOptions.length > 0 ?
                    productOptions.map((product, key) => (
                        <option key={key} value={product.id}>{product.name}</option>
                    ))
                    : ''}
                </select>
            </Col>
            
        </Row>

        <Row className="mt-4">
          {foundData ? 
          <Col md={12}>
                     <Card>
                <CardBody>
                  <CardTitle className="mb-4">Sale Report Summary</CardTitle>

                  <Form>
                  <Table className="table align-middle mb-0 table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th>Product</th>
                          {/* <th>Money In Trade</th>
                          <th>Waiting To Trade</th>
                          <th>Next Month Starting Balance</th>
                          <th>Next Month added to same quarter</th>
                          <th>Expire end quarter</th>
                          <th>Next Quarter Starting</th> */}
                        </tr>
                      </thead>
                      <tbody>
                   
                          <tr >
                            <td>{selectedProductName}</td>
                            {/* <td>{inTrade}</td>
                            <td>{newSales}</td>
                            <td>{newMonthAmount}</td>

                            <td>{NextQAmount}</td>
                            <td>{ExpireQAmount}</td>
                            <td>{nextQStarting}</td> */}
                          </tr>
          
                      </tbody>

                      <thead className="table-light">
                        <tr>
                          <th>Money In Trade</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr >
                            <td>{inTrade}</td>
                          </tr>

                      </tbody>

                      <thead className="table-light">
                        <tr>
                        <th>Waiting To Trade</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr >
                          <td>{newSales}</td>
                          </tr>

                      </tbody>

                      <thead className="table-light">
                        <tr>
                        <th>Next Month Starting Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr >
                          <td>{newMonthAmount}</td>
                          </tr>

                      </tbody>

                      <thead className="table-light">
                        <tr>
                        <th>Next Month added to same quarter</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr >
                          <td>{NextQAmount}</td>
                          </tr>

                      </tbody>

                      <thead className="table-light">
                        <tr>
                        <th>Expire end quarter</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr >
                          <td>{ExpireQAmount}</td>
                          </tr>

                      </tbody>

                      <thead className="table-light">
                        <tr>
                        <th>Next Quarter Starting</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr >
                          <td>{nextQStarting}</td>
                          </tr>

                      </tbody>


                    </Table>
                  </Form>
                </CardBody>
              </Card>
          </Col>
            
          : ""}
        </Row>

        <Row className="mt-4">
          {foundData ? 
          <div>
            {/* <h3>Total: {totalAmount}</h3> //This can be added to relevant reports */}
            <UserSingleTable tableData={loadData} columns={columns} title={"Product Sales"} />
          </div>
            
          : "No Data Available"}
        </Row>
        </div>
        }
    </div>
  )
}
SalesReportContainer.propTypes = {
    displayAllSelection: PropTypes.func,
  }
export default SalesReportContainer