import React from 'react'
import {Page, Text, Font, Image, View, Document, StyleSheet, PageHeader } from '@react-pdf/renderer';

import c1Logo from '../../../assets/uploads/logo/c1logo.png'

function PDFTransactions({title, name, info, usdtProductTotal=0, btcProductTotal=0, userid, date, totalUSDT, totalBTC, totalZAR, applyFilter, dateTo, dateFrom, currency, countryCurrencyDisplay}) {

    // const currency = {
    //     '1': 'USDT',
    //     '2': 'ZAR',
    //     '3': 'BTC'
    // }

    const status = {
        '1': 'Approved',
        '2': 'Declined',
        '0': 'Pending'
    }

    Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
      });
      
      const styles = StyleSheet.create({
        body: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
        },
        title: {
          fontSize: 26,
          textAlign: 'center',
          fontFamily: 'Oswald'
        },
        pdfHeader: {
            display: 'inline-block'
        },
        author: {
          fontSize: 18,
          textAlign: 'center',
          marginBottom: 40,
        },
        blockAuthor: {
          fontSize: 16,
          textAlign: 'center',
          marginTop: 15,
        },
        subtitle: {
          fontSize: 14,
          margin: 12,
          fontFamily: 'Oswald',
          display: 'inline'
        },
        text: {
          margin: 12,
          fontSize: 14,
          textAlign: 'justify',
          fontFamily: 'Times-Roman'
        },
        image: {
          marginVertical: 15,
          marginHorizontal: 220,
          height: "100px",
          width: "100px",
          textAlign: "center",
            display: 'inline-block'
        },
        header: {
          fontSize: 12,
          marginBottom: 20,
          textAlign: 'center',
          color: 'grey',
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
        },
        inlineBlock: {
              display: "flex",
          flexDirection: "row",
          fontSize: 14,
        },
        inlineBlock2: {
          marginTop: "20px",
          backgroundColor: "#030B15",
          color: "white",
          display: "flex",
          flexDirection: "row",
          fontSize: 12,
        },
        inlineBlock3: {
          // backgroundColor: "#d0d9d3",
          color: "black",
          border: "1px solid black",
          display: "flex",
          flexDirection: "row",
          fontSize: 9,
        },
      });

      const totalAmountUSDT = info.reduce((total, transaction) => {
        if (
          transaction.currency === "1" &&
          transaction.status === "1" &&
          transaction.tran_cat === "1"
        ) {
          return total + parseFloat(transaction.amount);
        }
        return total;
      }, 0);

      const totalAmountZAR = info.reduce((total, transaction) => {
        if (
          transaction.currency === "2" &&
          transaction.status === "1" &&
          transaction.tran_cat === "1"
        ) {
          return total + parseFloat(transaction.amount);
        }
        return total;
      }, 0);

      const totalAmountBTC = info.reduce((total, transaction) => {
        if (
          transaction.currency === "3" &&
          transaction.status === "1" &&
          transaction.tran_cat === "1"
        ) {
          return total + parseFloat(transaction.amount);
        }
        return total;
      }, 0);

      
      const totalAmountUSDTInterest = info.reduce((total, transaction) => {
        if (
          transaction.currency === "1" &&
          transaction.status === "1" &&
          transaction.tran_cat === "4"
        ) {
          return total + parseFloat(transaction.amount);
        }
        return total;
      }, 0);

      const totalAmountZARInterest = info.reduce((total, transaction) => {
        if (
          transaction.currency === "2" &&
          transaction.status === "1" &&
          transaction.tran_cat === "4"
        ) {
          return total + parseFloat(transaction.amount);
        }
        return total;
      }, 0);

      const totalAmountBTCInterest = info.reduce((total, transaction) => {
        if (
          transaction.currency === "3" &&
          transaction.status === "1" &&
          transaction.tran_cat === "4"
        ) {
          return total + parseFloat(transaction.amount);
        }
        return total;
      }, 0);

      var haveUSDT = false;
      var haveZAR = false;
      var haveBTC = false;

      const checkAll = info.reduce((total, transaction) => {
        if (
          transaction.currency === "1" &&
          transaction.status === "1"
        ) {
          haveUSDT = true;
        }

        if (
          transaction.currency === "2" &&
          transaction.status === "1"
        ) {
          haveZAR = true;
        }

        if (
          transaction.currency === "3" &&
          transaction.status === "1"
        ) {
          haveBTC = true;
        }
       
      }, false);

      function truncateDecimals(number, decimalPlaces) {
        const multiplier = 10 ** decimalPlaces;
        return Math.floor(number * multiplier) / multiplier;
      }

     
      const MyHeader = ({ title, name, userid, date, applyFilter, dateFrom, dateTo }) => (
        <View>
          <Image style={styles.image} src={c1Logo} />
          <Text style={styles.title}>C1 FS</Text>
          <Text style={styles.author}>{title}</Text>
          <View style={styles.inlineBlock}>
            <View style={{ flex: 1 }}>
              <Text>{name}</Text>
              <Text>Customer ID: {userid}</Text>
              <Text>Date Issued: {date}</Text>
              {applyFilter ? (
                <View>
                  <Text>Date From: {dateFrom}</Text>
                  <Text>Date To: {dateTo}</Text>
                </View>
              ) : null}
            </View>
            <View style={{ flex: 1 }}>
              <Text>C1 FS (Pty) Ltd</Text>
              <Text>The Old Tannery</Text>
              <Text>Block C</Text>
              <Text>Hermon Road</Text>
              <Text>Wellington</Text>
              <Text>7654</Text>
            </View>
          </View>
        </View>
      );
      

  return (
    <Document>
      <Page style={styles.body}>
        <MyHeader title={title} name={name} userid={userid} date={date} applyFilter={applyFilter} dateFrom={dateFrom} dateTo={dateTo} />
      
        <View>
      <Text style={styles.blockAuthor}>USDT</Text>
      <View style={styles.inlineBlock2}>
      	<View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
        	<Text >Date</Text>
        </View>
        <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
        	<Text >Name</Text>
        </View>
        <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
        	<Text >Description</Text>
        </View>
        <View style={{ flex: 2, padding: "5px", textAlign: "center"  }}>
        	<Text >Transaction ID</Text>
        </View>
             <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
        	<Text >Amount</Text>
        </View>
             <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
        	<Text >Status</Text>
        </View>
      </View>
      
        {haveUSDT ?
        info.map((transaction,key) => (
          currency[transaction.currency] === 'USDT'?
          <View key={key} style={styles.inlineBlock3}  wrap={false} >
    
                  <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black" }}>
                    <Text >{transaction.createdAt}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black" }}>
                    <Text >{name}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{transaction.tran_type}</Text>
                </View>
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{transaction.trans_id}</Text>
                </View>
                     <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{transaction.currency === '3' ? currency[transaction.currency] + truncateDecimals(transaction.amount, 5) :  currency[transaction.currency] + truncateDecimals(transaction.amount,2)}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{status[transaction.status]}</Text>
                </View>
              {/* </View> */}
       
              </View>
              : ''
              // : 
              //   ''
               
          
        ))
        :
        <View style={styles.inlineBlock3}  wrap={false} >
        <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
            <Text >No record found!</Text>
        </View>
      </View>
      
      }
        </View>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
      </Page>

      <Page style={styles.body}>
        <MyHeader title={title} name={name} userid={userid} date={date} applyFilter={applyFilter} dateFrom={dateFrom} dateTo={dateTo} />
        <View>
      <Text style={styles.blockAuthor}>BTC</Text>
      <View style={styles.inlineBlock2}>
      	<View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
        	<Text >Date</Text>
        </View>
        <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
        	<Text >Name</Text>
        </View>
        <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
        	<Text >Description</Text>
        </View>
        <View style={{ flex: 2, padding: "5px", textAlign: "center"  }}>
        	<Text >Transaction ID</Text>
        </View>
             <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
        	<Text >Amount</Text>
        </View>
             <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
        	<Text >Status</Text>
        </View>
      </View>
      
        {haveBTC? 
        info.map((transaction,key) => (
          currency[transaction.currency] === 'BTC'?
          <View key={key} style={styles.inlineBlock3}  wrap={false} >
          {/* {currency[transaction.currency] === 'USDT'? 
            <View > */}
                  <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black" }}>
                    <Text >{transaction.createdAt}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black" }}>
                    <Text >{name}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{transaction.tran_type}</Text>
                </View>
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{transaction.trans_id}</Text>
                </View>
                     <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{transaction.currency === '3' ? currency[transaction.currency] + truncateDecimals(transaction.amount, 5) :  currency[transaction.currency] + truncateDecimals(transaction.amount, 2)}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{status[transaction.status]}</Text>
                </View>
              {/* </View> */}
       
              </View>
              // : ''
              : 
                ''


        ))
        :
        <View style={styles.inlineBlock3}  wrap={false} >
        <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
            <Text >No record found!</Text>
        </View>
      </View>
        
        }
        </View>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />

      </Page>


      <Page style={styles.body}>
        <MyHeader title={title} name={name} userid={userid} date={date} applyFilter={applyFilter} dateFrom={dateFrom} dateTo={dateTo} />
        <View>
      <Text style={styles.blockAuthor}>{countryCurrencyDisplay}</Text>
      <View style={styles.inlineBlock2}>
      	<View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
        	<Text >Date</Text>
        </View>
        <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
        	<Text >Name</Text>
        </View>
        <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
        	<Text >Description</Text>
        </View>
        <View style={{ flex: 2, padding: "5px", textAlign: "center"  }}>
        	<Text >Transaction ID</Text>
        </View>
             <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
        	<Text >Amount</Text>
        </View>
             <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
        	<Text >Status</Text>
        </View>
      </View>
      
        {haveZAR ?
        info.map((transaction,key) => (
          currency[transaction.currency] === countryCurrencyDisplay?
          <View key={key} style={styles.inlineBlock3}  wrap={false} >
          {/* {currency[transaction.currency] === 'USDT'? 
            <View > */}
                  <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black" }}>
                    <Text >{transaction.createdAt}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black" }}>
                    <Text >{name}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{transaction.tran_type}</Text>
                </View>
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{transaction.trans_id}</Text>
                </View>
                     <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{transaction.currency === '3' ? currency[transaction.currency] + truncateDecimals(transaction.amount, 5) :  currency[transaction.currency] + truncateDecimals(transaction.amount, 2)}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{status[transaction.status]}</Text>
                </View>
              {/* </View> */}
       
              </View>
              // : ''
              : 
       
                ''
        ))
        :
        <View style={styles.inlineBlock3}  wrap={false} >
        <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
            <Text >No record found!</Text>
        </View>
      </View>
      }
        </View>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />

        </Page>


<Page style={styles.body}>
        <MyHeader title={"Summary Report:"} name={name} userid={userid} date={date} applyFilter={applyFilter} dateFrom={dateFrom} dateTo={dateTo} />

          
            <View>
            <Text style={styles.text}>
                Total In Withdrawable Wallet:
            </Text>
            <View style={styles.inlineBlock3} wrap={false}>

                
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >USDT {totalUSDT}</Text>
                </View>


                
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{countryCurrencyDisplay} {totalZAR}</Text>
                </View>

    
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >BTC {totalBTC}</Text>
                </View>

            </View>
            </View>

            <View>
            <Text style={styles.text}>
                Total Deposit Amount:
            </Text>
            <View style={styles.inlineBlock3} wrap={false}>

                
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >USDT {totalAmountUSDT}</Text>
                </View>


                
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{countryCurrencyDisplay} {totalAmountZAR}</Text>
                </View>

    
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >BTC {totalAmountBTC}</Text>
                </View>

            </View>
            </View>

            <View>
            <Text style={styles.text}>
            Total Proceeds Earned:
            </Text>
            <View style={styles.inlineBlock3} wrap={false}>

                
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >USDT {truncateDecimals(totalAmountUSDTInterest, 2)}</Text>
                </View>


                
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >{countryCurrencyDisplay} {truncateDecimals(totalAmountZARInterest, 2)}</Text>
                </View>

    
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >BTC {totalAmountBTCInterest}</Text>
                </View>

            </View>
            </View>

            <View>
            <Text style={styles.text}>
                Total In Products:
            </Text>
            <View style={styles.inlineBlock3} wrap={false}>

                
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >USDT {usdtProductTotal}</Text>
                </View>

                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                    <Text >BTC {btcProductTotal}</Text>
                </View>

            </View>
            </View>

            

 
  
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
  )
}

export default PDFTransactions