import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../../Dashboard/LatestTranactionCol";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
function PendingLTVUserLoans() {
    
    const[tableData, setTableData] =  React.useState([]);
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('Pending LTV Loans');

    const[transactions, setTransactions] =  React.useState([]);
    

    const columns = useMemo(
        () => [
          {
            Header: "Userid",
            accessor: "userid",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Collateral Currency",
            accessor: "collat_coin",
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
        
          },
          {
            Header: "Collateral Amount",
            accessor: "collateral_amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Loan Amount",
            accessor: "loan_amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Loan Coin",
            accessor: "loan_coin",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Liquidation Amount",
            accessor: "liquidation_amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p>
                  {parseFloat(cellProps.value).toFixed(2)}
                </p>
              );
            }
        
          },
          {
            Header: "Exchange Rate",
            accessor: "exchange_rate",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "C1FS Proceeds",
            accessor: "company_proceeds",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value == 'true' ? 'Yes' : 'No'}
                </p>
              );
            }
          },
          {
            Header: "Date",
            accessor: "createdAt",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Action",
            accessor: "id",
            disableFilters: true,
            Cell: cellProps => {
                return (
                    <div>
                    <button className="btn btn-rounded btn-success" onClick={(e) => {approveDeposit(cellProps.value)}}
                    >Approve </button>
                    <button className="btn btn-rounded btn-danger" onClick={(e) => {declineDeposit(cellProps.value)}}
                    >Decline </button>
                    </div>
                  );
            },
          },
    
        ],
        []
      );

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            getPendingUserLoans()
    
          }
        }
      }, [auth])
    
    
      const approveDeposit = async (id) => {

        var depositObj = {
            id: id,
            status: '1'
        }
    
        await axios.post(process.env.REACT_APP_BASE + "api/approve-ltv-loan-by-id", depositObj).then(async (res)=>{
            setTimeout(() => {window.location.reload(); }, 200);
            //await getPendingUserLoans()

          
        });
      }

      const declineDeposit = async (id) => {

        var depositObj = {
            id: id,
            status: '2'
        }
    
        await axios.post(process.env.REACT_APP_BASE + "api/decline-ltv-loan-by-id", depositObj).then(async (res)=>{
            
            if(res.data.status === 200)
                await getPendingUserLoans()
            else
                setTransactions([])
          
        });
      }

      const getPendingUserLoans = async () => {
        
        await axios.get(process.env.REACT_APP_BASE + "api/pending-ltv-loans").then(async (res)=>{
            console.log(res.data)
            if(res.data.status === 200)
                setTransactions(res.data.loans)
            else
                setTransactions([])
          
        });
      }

  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="LTV Loans" breadcrumbItem="LTV Loans" />

<Row>
  <Col md="12" sm="12">
        <UserSingleTable tableData={transactions} columns={columns} title={title} />
        </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PendingLTVUserLoans