import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../../UserContext";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const AllocateAdminInterest =()=>{

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [userBalance, setuserBalance] = React.useState('');
  const [userDeposit, setDeposit] = React.useState('');
  const [userWithdrawal, setWithdrawal] = React.useState('');
  const [userDepositTransactions, setUserDepositTransactions] = React.useState([]);
  const [userWithdrawalTransactions, setUserWithdrawalTransactions] = React.useState([]);
  const [viewAllTransactions, setAllTransactions] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);
  const [allDates, setAllDates] = React.useState([]);

  const [currentDateId, setCurrentDateId] = React.useState('');
  const [vipOption, setVipOption] = React.useState('false');

  
  const [forUser, setForUser] = React.useState('');
  
  
  const [currentPercentage, setCurrentPercentage] = React.useState('');
  const [foundProducts, setFoundProducts] = React.useState(false);
  const [getProductid, setCurrentProductId] = React.useState('');
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])


React.useEffect( () => {
    
    if (auth != null) {
      if (!auth) {
        window.location.href="/login"
      }else{
          // 
          setuserName(user.user.name);
          setuserEmail(user.user.email);
          setuserUserid(user.user.userid);

          getAllProducts(user.user.userid)
          setFoundProducts(true)
          getAllDates(user.user.userid)
      }
    }
  }, [auth])

const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}



const getAllProducts = async userid => {
 
  await axios.get(process.env.REACT_APP_BASE + "api/all-products").then(async res =>{

    console.log('set all products ', res.data.Products)
    return setAllProducts(res.data.Products);
 
  })

}

const getAllDates = async userid => {
 
  await axios.get(process.env.REACT_APP_BASE + "api/payment-dates").then(async res =>{
    // allDates
    return setAllDates(res.data.dates);
 
  })

}

const setProductInterest = async (percentage) => {

    // var productid = req.body.productid;
    // var adminuserid = req.body.userid;
    // var percentage = req.body.percentage;
    // var dateid = req.body.dateid;
    // var fromadmin = req.body.fromadmin;

  const InterestObject = {
    productid: getProductid,
    adminuserid: forUser,
    percentage: currentPercentage,
    dateid: currentDateId,
    fromadmin: userUserid
  }

  console.log(getProductid)

  await axios.post(process.env.REACT_APP_BASE + "api/create-admin-interest-allocate",InterestObject).then(async res =>{

    console.log(res.data)
    
    if(res.data.status === 200)
    {
        toast.success('Created Allocation', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }else{
        toast.error('Unable to create allocation', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }

  });
}

    return(
      <React.Fragment>
      <div className="page-content">
      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />

        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="Add Product Interest" />

          <Row>

          <Col xl="12">
              <Card>
                <CardBody>
                <h4 className="card-title mb-4">Product Interest</h4>

                <div className="mt-4 mb-3">

                  <TabContent activeTab={activeTab} className="mt-4">
                    <TabPane tabId="1" id="buy-tab">


                      <div>
                        <div>
                        <Label>Dates:</Label>
                        <InputGroup className="mb-3">
                            <select className="form-control" name="allproducts" id="allproducts" onChange={(e)=>setCurrentDateId(e.target.value)} value={currentDateId}>
                                <option className="form-control"  value="">Select Payment Date</option>
                                {allDates.map((date, key) => (
                                    <option className="form-control" key={date.id} value={date.id}>{date.payment_date}</option>
                                ))}

                            </select>
                        </InputGroup>

                        <Label>Products:</Label>
                        <InputGroup className="mb-3">
                            <select className="form-control" name="allproducts" id="allproducts" onChange={(e)=>setCurrentProductId(e.target.value)} value={getProductid}>
                                <option  value="">Select Product</option>
                                {/* {allProducts.map((product, key) => ( */}
                                    <option className="form-control" value="15">Transaction Accumulation Fund</option>
                                {/* ))} */}

                            </select>
                        </InputGroup>

                        <Label>Select User to Allocate Interest:</Label>
                        <InputGroup className="mb-3">
                            <select className="form-control" name="allproducts" id="allproducts" onChange={(e)=>setForUser(e.target.value)} value={forUser}>
                                <option  value="">Select Option</option>
                                <option className="form-control" value="C1A0000002">C1A0000002</option>
                            </select>
                        </InputGroup>

                          <Label>Percentage :</Label>

                          <InputGroup className="mb-3">
                        
                            <Input type="number" min="1" step="1" max={userBalance} start="70" onChange={(e)=>setCurrentPercentage(e.target.value)} value={currentPercentage} className="form-control" />
                            <Label className="input-group-text">%</Label>
                          </InputGroup>
                        </div>

                        <div className="text-center">
                          <Button
                            onClick={(e)=>setProductInterest(e.target.value)}
                            type="button"
                            color="success"
                            className="w-md bg-success"
                          >
                            Allocate Interest
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                   
                  </TabContent>
                </div>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
      </div>
    </React.Fragment>
    )
}