import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, Row, Input, Label, FormFeedback } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/c1_logo_text.png";
import CarouselPage from "./CarouselPage";

import {useState,useEffect} from "react";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login2 = () => {

  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')

  //meta title
  document.title="Login | C1 FS Admin";

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    }
  });

  const loginFunc = async() => {
    var formD = new FormData();

    console.log(email, password)
    formD.append('username',email)
    formD.append('password',password)
    formD.append('isAdmin',true)

    var userData = {
      username: email,
      password: password,
      isAdmin: true
    }
    

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login-admin`,userData,{withCredentials:true}).then((res)=>{

      if(res.data.status == 200)
      {
        // It is successfull client is logged in
        if(res !== undefined){
          // toastr.success(res.data.message)
        }
        if(res.data.auth)
        {
       
          toast.success('Successfully Logged In!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });

            setTimeout(() => {window.location.href="/dashboard" }, 2000);
        }
      }else{
     
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }

    })
  };

  const handleEmailChange = (email) => {
    setEmail(email)
  }

  const handlePasswordChange = (password) => {
    setPassword(password);
  }

  return (
    <React.Fragment>
      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          style={{ maxHeight: "250px" }}
                          className="auth-logo-dark"
                        />
                        <img
                          src={logodark}
                          alt=""
                          style={{ maxHeight: "250px" }}
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div>

                      <div>
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="Email"
                              value={email}
                              type="text"
                              placeholder="Enter Email"
                              onChange={(e) => {handleEmailChange(e.target.value)}}
                            />
          
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              value={password}
                              type="password"
                              placeholder="Enter Password"
                              onChange={(e) => {handlePasswordChange(e.target.value)}}
                            />
          
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                              onClick={loginFunc}
                            >
                              Log In
                            </button>
                          </div>

                        </Form>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} C1 FS Admin.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login2;
