import React, { useEffect, useRef } from "react";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getActiveElement } from "formik";

const CreateNew = props => {

    const{auth,user} = React.useContext(UserContext)
    const[role, setUserRole] =  React.useState('viewer');
    const[name, setName] =  React.useState('');
    const[lastname, setLastName] = React.useState('');
    const[email, setEmail] =  React.useState('');
    const[password, setPassword] =  React.useState('');
    const[referral, setReferral] =  React.useState('');
    const[referralParent, setReferralParent] =  React.useState('');
    const[referralArray, setReferralArray] =  React.useState([]);
    const[onceOffFee, setOnceOffFee] =  React.useState(0);
    const[maxOnceOffFee, setMaxOnceOffFee] =  React.useState(0); 
    const[cell, setCell] = React.useState('');
    const[userRole, setAdminRole] = React.useState('');
    // |Admin

    const[userid, setUserid] = React.useState('');
  
    React.useEffect( () => {
    
      if (auth != null) {
        if (!auth) {
          window.location.href="/login"
        }else{
            // 
            if(user.user.u_role === 'admin')
            {
              setAdminRole(user.user.u_role)
              setUserid(user.user.userid)
              agentReferralParent(user.user.userid)
            }else{
            getAllReferralArray()
            }
        }
      }
    }, [auth])

    const getAllReferralArray = async() => {
      var agentArr = []
      var requiredObj = {
        role: 'admin'
      }
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}find-all-admins`,requiredObj).then((res)=>{
        if(res.data.status === 200)
        {
          console.log(res.data.response)
          setReferralArray(res.data.response)
          agentArr = res.data.response
        }else{
          toast.error('Unable to find any data', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }
      })

      return agentArr
    }

    const agentReferralParent = async (parent) => {
      
      var refferralArr = await getAllReferralArray()
      console.log("agent parent " + parent, refferralArr)
      setReferralParent(parent)
      var targetObject = refferralArr.find(obj => obj.userid === parent);
      console.log(targetObject)
      if(targetObject)
      {
        setMaxOnceOffFee(parseFloat(targetObject.onceOffPayment))
      }
      
    }

    const roleChange = async (role) => {
        setUserRole(role);
    }

    const nameChange = async (name) => {
        setName(name);
    }

    const lastNameChange = async (lastName) => {
      setLastName(lastName);
    }

    const emailChange = async (email) => {
        setEmail(email);
    }

    const passwordChange = async (password) => {
        setPassword(password);
    }

    const referralChange = async (referral) => {
        setReferral(referral);
    }

    const onOnceOffFeeChange = async (fee) => {
      setOnceOffFee(fee)
    }

    const onSubmitAdmin = async () => {
        if(role === '')
        {
            return toast.error('Select Role', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        if(name === '')
        {
            return toast.error('Enter Name', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        if(lastname === '')
        {
            return toast.error('Enter Last Name', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        if(email === '')
        {
            return toast.error('Enter Email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        if(password === '')
        {
            return toast.error('Enter Password', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        // if(referral === '')
        // {
        //     return toast.error('Enter Referral', {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "dark",
        //         });
        // }

        if(role === 'viewer')
        {
          setOnceOffFee(0)
        }

        console.log(role === 'agent',  parseInt(onceOffFee) <= maxOnceOffFee, parseInt(onceOffFee), maxOnceOffFee)

        if(role === 'agent' && parseInt(onceOffFee) > maxOnceOffFee)
        {
          return toast.error('Fee is more than Max Fee', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }

        console.log("fee set is  " + onceOffFee)

        var formObj = {
            name: name,
            lastname: lastname,
            role: role,
            password: password,
            cell: cell,
            // referral:referral,
            referral: referralParent,
            email: email,
            onceOffFee: onceOffFee,
        }

        console.log("the parent referral is ", referralParent)
        // return false;

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register-new-admin`,formObj).then((res)=>{

            if(res.data.status == 200)
            {
                return toast.success('SuccessFully Registered', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }else{
                return toast.error( res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }

        })
    }

    const changeReferralParent = async (parent) => {
      console.log("changed to parent " + parent)
      setReferralParent(parent)
      var targetObject = referralArray.find(obj => obj.userid === parent);
      console.log(targetObject)
      if(targetObject)
      {
        setMaxOnceOffFee(parseFloat(targetObject.onceOffPayment))
      }
      
    }


  //meta title
  document.title = "New Admin | C1 FS";

  return (
    <React.Fragment>
            <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="New User" />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New User</CardTitle>

                  <Form>
                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">First name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="First Name"
                        value={name}
                        onChange={(e) => {nameChange(e.target.value)}}
                      />
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Last Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Last Name"
                        value={lastname}
                        onChange={(e) => {lastNameChange(e.target.value)}}
                      />
                    </div>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Enter Admin Email ID"
                            value={email}
                            onChange={(e) => {emailChange(e.target.value)}}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Password</Label>
                          <Input
                            type="password"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder="Enter Your Password"
                            value={password}
                            onChange={(e) => {passwordChange(e.target.value)}}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                       <Col lg={8} sm={12}>
                        {/*<div className="mb-3">
                          <Label htmlFor="formrow-InputCity">Enter Referral Code (enter <strong>all</strong> if super admin)</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-InputCity"
                            placeholder="Enter Referral Code"
                            value={referral}
                            onChange={(e) => {referralChange(e.target.value)}}
                          />
                        </div> */}

                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">Admin Parent</Label>
                          
                          <select
                            id="formrow-InputState"
                            disabled={userRole === 'admin' ? true : false}
                            className="form-control"
                            value={referralParent}
                            onChange={(e) => {changeReferralParent(e.target.value)}}
                          >
                            <option value="">Select</option>
                            {
                              // console.log(referralArray)
                            // referralArray.map((adminReferral, key) => {
                            //   <option key={key} value={adminReferral.userid}>{adminReferral.name}</option>
                            // })
                            referralArray.map((adminReferral, index) => (
                              <option key={index} value={adminReferral.userid}>{adminReferral.name}</option>
                            ))
                            }
                            
                          </select>
                        </div>
                      </Col> 
                      
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">Role</Label>
                          <select
                           
                            id="formrow-InputState"
                            className="form-control"
                            value={role}
                            onChange={(e) => {roleChange(e.target.value)}}
                          >
                            {/* <option value="admin">Admin</option>
                            <option value="super">Super Admin</option> */}
                            <option value="viewer">Assistant</option>
                            <option value="agent">Agent</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    {role === 'agent' ? 
                     <Row>
                      <Col md={8} >

                 
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">Would the admin like to share a once off fee with this agent? (Max={maxOnceOffFee}%)</Label>
                          <input onChange={(e) => {onOnceOffFeeChange(e.target.value)}} className="form-control" type="number" value={onceOffFee} step="1" min="0" max={maxOnceOffFee} />
                        </div>
      
               
                      {/*
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">Enter Referral Code (enter <strong>all</strong> if super admin)</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-InputCity"
                            placeholder="Enter Referral Code"
                            value={referral}
                            onChange={(e) => {referralChange(e.target.value)}}
                          />
                        </div>
                      */}
                      </Col>

                      <Col md={8} >
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">Agent Cell</Label>
                          <input onChange={(e) => {setCell(e.target.value)}} className="form-control" type="text" value={cell}  />
                        </div>
                        </Col>
                    </Row> 
                    : ''}
                    <div>
                      <a onClick={onSubmitAdmin} className="btn btn-primary w-md">
                        Submit
                      </a>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
        </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  );
};

export default CreateNew;
