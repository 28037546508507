import React, { useEffect, useState } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner,
    Form,
    Label,
    Input
  } from "reactstrap";

  import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function EditAdmin() {

    const{auth,user} = React.useContext(UserContext)
    const[userInfo, setUser] =  React.useState([]);
    const[userProfileInfo, setUserInfo] =  React.useState([]);

    
    const[notificationDescription, setNotificationDescription] =  React.useState('');
    const[notificationTitle, setNotificationTitle] =  React.useState('');

    // --------------New----------------------
    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [registrationLink, setRegistrationLink] = React.useState('')
    const [userParent, setUserParent] = React.useState('')
    const[userRole, setUserRole] =  React.useState('');
    const[onceOffFee, setOnceOffFee] =  React.useState(0);
    const[maxOnceOffFee, setMaxOnceOffFee] =  React.useState(0); 
    const[referralArray, setReferralArray] =  React.useState([]);
    const[referralParent, setReferralParent] =  React.useState('');






    // -------------End New--------------------------------------------------------

   
    const[uStatus, setUStatus] =  React.useState('P');
    const[kycStatus, setKYCStatus] =  React.useState('N');
    const[vip, setVIP] =  React.useState(false);
    const[payment, setPayment] =  React.useState('N');
    const[cipc, setCIPC] =  React.useState('');
  

    const [idupload, setIDUpload] = React.useState('')
    const [poaupload, setPOAUpload] = React.useState('')
    const [cipcupload, setCIPCupload] = React.useState('')

    const [adminUserid, setAdminUserid] = React.useState('')

    
    const[usdtWalletet, setusdtWalletet] =  React.useState(0);
    const[btcWalletet,  setbtcWalletet] =  React.useState(0);
    const[zarWalletet,  setzarWalletet] =  React.useState(0);

    const[usdtProduct, setusdtProduct] =  React.useState(0);
    const[zarProduct, setzarProduct] =  React.useState(0);

    
    // const [displayUserSpinner, setDisplayUserSpinner] = React.useState(true)
    const[displayUserSpinner, setDisplayUserSpinner] =  React.useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [btcAmount, setBtcAmount] = useState(0);
    const [zarAmount, setZarAmount] = useState(0);
    const [usdtAmount, setUsdtAmount] = useState(0);

    // Add if statements to onboarding fees
    const[addIfQuery, setAddIfQuery] =  React.useState(false);
    

    let { id } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
            getUserInfo(user.user.userid)
            setAdminUserid(user.user.userid)
    
          }
        }
      }, [auth])

      const getUserInfo = async (userid) => {
        var userObj = {
            id: id,
            userid: userid
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/find-admin-by-id`,userObj).then(async (res)=>{
            console.log("user data ", res.data, id, userid)
            if(res.data.status == 200)
            {
                
            //   setUserInfo(res.data.info)
            setUser(res.data.user)

            // ---------------------------
            setName(res.data.user.name);
            setEmail(res.data.user.email)
            var regLink = `https://portal.c1fs.co.za/register/${res.data.user.u_role==='agent' ? res.data.user.company_info.reg_link + '?userid='+ res.data.user.connected_user : res.data.user.company_info.reg_link}`
            setRegistrationLink(regLink)
            setUserParent(res.data.user.connected_user)
            setUserRole(res.data.user.u_role)

            if(res.data.user.u_role === 'agent')
            {
                
            const parentMeta = res.data.user.adminmetas.find(item => item.meta_key === 'admin_parent');

            if (parentMeta) {
            const parentMetaValue = parentMeta.meta_value;
            console.log(parentMetaValue); // Output: "5"\
                changeReferralParent(parentMetaValue)
            } else {
            console.log('No parent value found for this agent.');
            }
       
            }

            // Now get the payment fee details

            const paymentFeeMeta = res.data.user.adminmetas.find(item => item.meta_key === 'payment_fee');

            if (paymentFeeMeta) {
            const paymentFeeValue = paymentFeeMeta.meta_value;
            console.log(paymentFeeValue); // Output: "5"\
            setOnceOffFee(parseInt(paymentFeeValue))
            } else {
            console.log('No payment_fee value found.');
            }
             
            }else{
                window.location.href="/admin-users"
            }
      
          })
      }


      const onOnceOffFeeChange = async (fee) => {
        setOnceOffFee(fee)
      }

      const onSubmitAdmin = async () => {
        console.log("Saved")

        if(userRole === 'agent' && parseInt(onceOffFee) > maxOnceOffFee)
        {
          return toast.error('Fee is more than Max Fee', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }


        var userObj = {
            meta_key: 'payment_fee',
            meta_value: onceOffFee,
            userid: userInfo.userid
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-admin-meta`,userObj).then(async (res)=>{
            if(res.data.status === 200)
            {
                return toast.success('We have updated the payment fee. We advice to ensure that the other agents fee does not exceed the admin fee.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }else{
                return toast.error('Unable to update fee', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
        });

        // Lets see if this is an if query
        if(addIfQuery)
        {
          if(!selectedOption)
            return;

          
          var feeQueryObj = {
            option: selectedOption,
            1: usdtAmount,
            2: zarAmount,
            3: btcAmount
          }

          var queryObj = {
              meta_key: 'payment_fee_query',
              meta_value: JSON.stringify(feeQueryObj),
              userid: userInfo.userid
          }
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-admin-meta`,queryObj).then(async (res)=>{
              if(res.data.status === 200)
              {
                  return toast.success('We havve also added the if query.', {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                      });
              }else{
                  return toast.error('Unable to update fee', {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                      });
              }
          });
          
        }
      }

      const changeReferralParent = async (parent) => {
      
        var refferralArr = await getAllReferralArray()
        console.log("changed to parent " + parent, refferralArr)
        setReferralParent(parent)
        var targetObject = refferralArr.find(obj => obj.userid === parent);
        console.log(targetObject)
        if(targetObject)
        {
          setMaxOnceOffFee(parseFloat(targetObject.onceOffPayment))
        }
        
      }

      const getAllReferralArray = async() => {
        var requiredObj = {
          role: 'admin'
        }
        var newArr = []
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}find-all-admins`,requiredObj).then((res)=>{
          if(res.data.status === 200)
          {
 
            setReferralArray(res.data.response)
            newArr = res.data.response
            
          }else{
            toast.error('Unable to find any Admin Parent data', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
          }
        })

        return newArr;
      }

      const takeBackToAdmin = () => {
        console.log("clicked take back")
        window.location.href="/admin-users"
      }

      const addIfFeeQuery = async(newValue) => {
        console.log("Clicked If query ", !newValue)
        setAddIfQuery(!newValue)
      }

      const handleOptionClick = (option) => {
        setSelectedOption(option);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Perform your logic based on the selectedOption and entered amounts (btcAmount, zarAmount, usdtAmount)
        console.log('Selected Option:', selectedOption);
        console.log('BTC Amount:', btcAmount);
        console.log('ZAR Amount:', zarAmount);
        console.log('USDT Amount:', usdtAmount);
      };


  return (
    <React.Fragment>
              <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
                  <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Edit User" />
          <Row>
            <Col lg={8}>
             
              <Card>
              
                <CardBody>
                <Button className="btn btn-danger w-md mb-3" onClick={takeBackToAdmin} >Back</Button>
                  <CardTitle className="mb-4">Edit User</CardTitle>

                  {/* <Form> */}
                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Names</Label>
                      <Input
                        disabled
                        type="text"
                        className="form-control text-light bg-secondary"
                        id="formrow-firstname-Input"
                        placeholder="Name"
                        value={name}
                       
                      />
                    </div>

                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            disabled
                            type="email"
                            className="form-control text-light bg-secondary"
                            id="formrow-email-Input"
                            placeholder="Enter Admin Email ID"
                            value={email}
                    
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Registration Link</Label>
                          <Input
                            disabled
                            type="text"
                            className="form-control text-light bg-secondary"
                            id="formrow-password-Input"
                            placeholder="Registration Link"
                            value={registrationLink}
                           
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                    <Col md={8}> 
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputParent">Admin Parent</Label>
                          <Input
                            disabled
                            type="text"
                            className="form-control text-light bg-secondary"
                            id="formrow-InputParent"
                            placeholder="Admin Parent"
                            value={userParent}
         
                          />
                        </div>
                      </Col> 
                      
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputRole">Role</Label>
                          <Input
                            disabled
                            type="text"
                            className="form-control text-light bg-secondary"
                            id="formrow-InputRole"
                            placeholder="User Role"
                            value={userRole}
                   
                          />
                        </div>
                      </Col>
                    </Row>
                    {userRole === 'agent' ? 
                     <Row>
                      <Col md={8} >

                 
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">Agent Fee (Max={maxOnceOffFee}%)</Label>
                          <input onChange={(e) => {onOnceOffFeeChange(e.target.value)}} className="form-control" type="number" value={onceOffFee} step="1" min="0" max={maxOnceOffFee} />
                        </div>
      
    
                      </Col>

                    </Row> 
                    : 
                    <Row>
                    <Col md={8} >

               
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputState">Admin Fee</Label>
                        <input onChange={(e) => {onOnceOffFeeChange(e.target.value)}} className="form-control" type="number" value={onceOffFee} step="1" min="0" max={100} />
                      </div>
    
  
                    </Col>

                    <Col md={4} >
                    <button
                      className="btn btn-warning mt-4"
                      onClick={() => {
                        const newValue = !addIfQuery;
                        console.log("Clicked If query ", newValue);
                        setAddIfQuery(newValue);
                      }}
                    >
                      Add If Query
                    </button>
                    </Col>

                  

                  </Row>
                  }

                  {addIfQuery?
                  <div className="container mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <h2>Select an Option:</h2>
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className={`btn border-primary btn-${selectedOption === 'bigger' ? 'success' : 'light'}`}
                          onClick={() => handleOptionClick('bigger')}
                        >
                          Bigger Than
                        </button>
                        <button
                          type="button"
                          className={`btn border-primary btn-${selectedOption === 'less' ? 'success' : 'light'}`}
                          onClick={() => handleOptionClick('less')}
                        >
                          Less Than
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h2>Enter Amounts:</h2>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                        <Label htmlFor="formrow-firstname-Input">BTC</Label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="BTC Amount"
                            value={btcAmount}
                            onChange={(e) => setBtcAmount(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                        <Label htmlFor="formrow-firstname-Input">ZAR</Label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ZAR Amount"
                            value={zarAmount}
                            onChange={(e) => setZarAmount(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                        <Label htmlFor="formrow-firstname-Input">USDT</Label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="USDT Amount"
                            value={usdtAmount}
                            onChange={(e) => setUsdtAmount(e.target.value)}
                          />
                        </div>
                        {/* <button type="submit" className="btn btn-primary">
                          Submit
                        </button> */}
                      </form>
                    </div>
                  </div>
                </div>: ''}
                    <div>
                      <a onClick={onSubmitAdmin} className="btn btn-primary w-md">
                        save
                      </a>
                    </div>
                  {/* </Form> */}
                </CardBody>
              </Card>
            </Col>
        </Row>
        </Container>
        {/* container-fluid */}
      </div>

    </React.Fragment>

  )
}

export default EditAdmin