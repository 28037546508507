import { UserContext } from "UserContext";
import React, { useEffect, useState } from "react";
import axios from 'axios'

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

import io from "socket.io-client"

function PerformanceTable() {
    const socket = io('https://c1fs-node-server-66140b83eb8c.herokuapp.com', {});
    window.socket = socket;

    const { auth, user } = React.useContext(UserContext)
    const [userid, setUserid] = React.useState('');
    const [tableData, setTableData] = React.useState([]);
    const [loader, setLoader] = React.useState(false);

    useEffect(() => {
        if (auth != null) {
            if (!auth) {
                window.location.href = "/login"
            } else {
                if (user) {
                    setLoader(true)
                    setUserid(user.user.userid);
                    socket.on('get performanceDetails', getPerformanceDetails);
                    socket.emit('get performanceDetails', { data: user.user });
                }
            }
        }
        return () => {
            // Clean up the event listener when the component is unmounted
            socket.off('get performanceDetails', getPerformanceDetails);
        };
    }, [auth, user]);

    const getPerformanceDetails = (data) => {
        console.log("Received data from the socket:", data.data);
        // You can update the tableData state here if needed.
        // For example:
        setTableData(data.data);
        setLoader(false)
    };

    return (
        <div>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <CardTitle>Performance Monthly</CardTitle>
                            <div className="table-responsive">
                                <Table className="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th>Month</th>
                                            <th>Assets Under Management</th>
                                            <th>New Income USDT</th>
                                            <th>New Income ZAR</th>
                                            <th>New Income BTC</th>
                                            <th>Gain After Trading</th>
                                            <th>Withdrawals USDT</th>
                                            <th>Withdrawals ZAR</th>
                                            <th>Withdrawals BTC</th>
                                            <th>Forecast %</th>
                                            <th>Actual %</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loader ?
                                            <td>Loading...</td>
                                        
                                        : tableData && tableData.length > 0 ?
                                            tableData.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row">{data.date}</th>
                                                        <td>{data.totalManagementAmount}</td>
                                                        <td>{data.totalIncomeAmount ? data.totalIncomeAmount : 0}</td>
                                                        <td>{data.totalIncomeAmountZAR ? data.totalIncomeAmountZAR : 0}</td>
                                                        <td>{data.totalIncomeAmountBTC ? data.totalIncomeAmountBTC : 0}</td>
                                                        <td>{data.totalGainsFromTrading}</td>
                                                        <td>{data.totalWithdrawsUSDT ? data.totalWithdrawsUSDT * -1 : 0}</td>
                                                        <td>{data.totalWithdrawsZAR ? data.totalWithdrawsZAR * -1 : 0}</td>
                                                        <td>{data.totalWithdrawsBTC ? data.totalWithdrawsBTC * -1 : 0}</td>
                                                        <td>{data.forecast}</td>
                                                        <td>{data.actualPercentage}</td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            <tr>
                                                <td>No Data Found!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default PerformanceTable
