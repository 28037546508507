import React, { useEffect, useState } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";

  import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function EditUser() {

    const{auth,user} = React.useContext(UserContext)
    const[userInfo, setUser] =  React.useState([]);
    const[userProfileInfo, setUserInfo] =  React.useState([]);

    
    const[notificationDescription, setNotificationDescription] =  React.useState('');
    const[notificationTitle, setNotificationTitle] =  React.useState('');

   
    const[uStatus, setUStatus] =  React.useState('P');
    const[kycStatus, setKYCStatus] =  React.useState('N');
    const[vip, setVIP] =  React.useState(false);
    const[payment, setPayment] =  React.useState('N');
    const[cipc, setCIPC] =  React.useState('');
    const[userRole, setUserRole] =  React.useState('');

    const [idupload, setIDUpload] = React.useState('')
    const [idupload2, setIDUpload2] = React.useState('')
    const [poaupload, setPOAUpload] = React.useState('')
    const [cipcupload, setCIPCupload] = React.useState('')

    const [adminUserid, setAdminUserid] = React.useState('')

    
    const[usdtWalletet, setusdtWalletet] =  React.useState(0);
    const[btcWalletet,  setbtcWalletet] =  React.useState(0);
    const[zarWalletet,  setzarWalletet] =  React.useState(0);

    const[usdtProduct, setusdtProduct] =  React.useState(0);
    const[zarProduct, setzarProduct] =  React.useState(0);

    const[userCompany, setUserCompany] =  React.useState('');
    const[adminUser, setAdminUser] =  React.useState('');
    

    
    // const [displayUserSpinner, setDisplayUserSpinner] = React.useState(true)
    const[displayUserSpinner, setDisplayUserSpinner] =  React.useState(true);
    

    let { id } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
            console.log("productedt ", user.user)

            setAdminUser(user.user.productEdit)
            getUserInfo(user.user.userid)  
            setAdminUserid(user.user.userid)
            getProducts()
            getSavedProducts(id)
            setNotificationsLoading(false)
          }
        }
      }, [auth])

      const getUserInfo = async (userid) => {
        var userObj = {
            id: id,
            userid: userid
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/find-one-user-by-id`,userObj).then(async (res)=>{

            if(res.data.status == 200)
            {
            //   setUserInfo(res.data.info)
            console.log("the userinfo is: ", res.data.userinfo)
            setUser(res.data.user)
            setUserInfo(res.data.userinfo)
            setPersonalLoading(false)
            setDisplayUserSpinner(false);
            console.log(res.data)
            setUStatus(res.data.user.u_status)
            setUserRole(user.user.u_role)
            setKYCStatus(res.data.user.fica_status)
            setVIP(res.data.user.vip)
            setPayment(res.data.user.payment_status)
            setCIPC(res.data.userinfo.cipc)
            setUserCompany(res.data.user.company)

            var images = await getAllImages(res.data.userinfo)

            setusdtWalletet(res.data.wallets.usdt)
            setzarWalletet(res.data.wallets.zar)
            setbtcWalletet(res.data.wallets.btc)

            setusdtProduct(res.data.products.usdt)
            setzarProduct(res.data.products.zar)
            setBalanceLoading(false)
          
             
            }else{
                window.location.href="/users"
            }
      
          })
      }

      const getAllImages = async(info) => {

        var id = await axios
        .post(process.env.REACT_APP_API_BASE_URL + `getimage`, {image: info.idupload})
        .then(res2 => {
          setIDUpload(res2.data)
        })

        
        var id2 = await axios
        .post(process.env.REACT_APP_API_BASE_URL + `getimage`, {image: info.idupload2})
        .then(res2 => {
          setIDUpload2(res2.data)
        })

        var poa = await axios
        .post(process.env.REACT_APP_API_BASE_URL + `getimage`, {image: info.poaupload})
        .then(res2 => {
          setPOAUpload(res2.data)
        })

        var cipc = await axios
        .post(process.env.REACT_APP_API_BASE_URL + `getimage`, {image: info.cipcupload})
        .then(res2 => {
          setCIPCupload(res2.data)
        })
      }

      const sendNotification = async (userid) => {
        var notificationObj = {
            userid: userid,
            title: notificationTitle,
            message: notificationDescription,
            adminUserid: adminUserid
        }

        console.log(notificationObj)
        
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-notification`,notificationObj).then((res)=>{

            if(res.data.status == 200)
            {
                toast.success('Notification Sent', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });

                    setNotificationDescription('')
                    setNotificationTitle('')
             
            }else{
                toast.error('Unable to send Notification!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
      
          })
      }

      const changeNotificationDescription = async (description) => {
        console.log(description)
        setNotificationDescription(description)
      }

      const changeNotificationTitle = async (title) => {
        setNotificationTitle(title)
      }

      
      const approveKYC = async (title) => {
        setNotificationTitle(title)
        const updatedInfoObj = {
            status: uStatus,
            ficastatus: kycStatus,
            ficaoption: 'F',
            userid: userInfo.userid,
            paymentStatus: payment,
            vip: vip,
            adminuser: adminUserid
          }
  
          axios.post(process.env.REACT_APP_BASE + "api/update-status",updatedInfoObj).then(async res =>{
          
            if(res.data.status == 200)
            {
              // toastr.options.onHidden = function() { window.location.href="/user-list" } 
              
                toast.success('Updated Successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
            else
            toast.error('Unable To Update KYC Info', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
                return false
          });
      }

      const optionGroup = [
        { label: "Email Confirmation Required", value: "L" },
        { label: "Active", value: "A" },
        { label: "Pending", value: "P" },
        { label: "Hard Lock", value: "HL" },
        { label: "Soft Locked", value: "SL" }
      ]
      const ficaStatus = [
        { label: "Required", value: "N" },
        { label: "Pending", value: "P" },
        { label: "Active", value: "A" },
      ]
      const ficaOption = [
        { label: "Required", value: "N" },
        { label: "Basic Fica (Deprecated)", value: "B" },
        { label: "Full Fica", value: "F" },
      ]
      const paymentOption = [
        { label: "Required", value: "N" },
        { label: "Pending", value: "P" },
        { label: "Active", value: "A" },
      ]
      const vipOption = [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ]

      const[declineIDUpload, setDeclineIDUpload] =  React.useState(false);
      const[declineAddressUpload, setDeclineAddressUpload] =  React.useState(false);
      const[declineCIPCCUpload, setDeclineCIPCCUpload] =  React.useState(false);

      
      const[personalLoading, setPersonalLoading] =  React.useState(true);
      const[balanceLoading, setBalanceLoading] =  React.useState(true);
      const[accountStatusLoading, setAccountStatusLoading] =  React.useState(true);
      const[notificationsLoading, setNotificationsLoading] =  React.useState(true);

      const[declinedUploads, setDeclinedUploads] =  React.useState(false);

      const[declinedNotes, setDeclinedNotes] =  React.useState("");

      
      const[allProducts, setAllProducts] =  React.useState([]);
      const [selectedProducts, setSelectedProducts] = useState([]);
      const [savedProducts, setSavedProducts] = useState([]);

      const clickedDeclineButton = async(option) => {
        console.log("I have declined the upload ", declinedUploads)
        if(!declinedUploads)
        {
          
          setDeclinedUploads(true)
        }
        console.log("option clicked is ", option)

        if(option == "id")
        {
          console.log("I have declined the upload ID")
          setDeclineIDUpload(true)
        }else if(option == "poa")
        {
          console.log("I have declined the upload POA")
          setDeclineAddressUpload(true)
        }else if(option == "cipc")
        {
          setDeclineCIPCCUpload(true)
        }
      }

      const declineUploads = async(userid) => {
        console.log("user clicked on save declined uploades")
        if(declinedNotes === '')
        {
          toast.error('Please add a note in the notes section', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });

            return false;
        }

        if(!declineIDUpload && !declineAddressUpload && !declineCIPCCUpload)
        {
          toast.error('No uploaded selected', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });

            return false;
        }

        const declinedObj = {
          idupload: declineIDUpload,
          poaUpload: declineAddressUpload,
          cipccUpload: declineCIPCCUpload,
          notes: declinedNotes,
          userid: userid
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}declined-kyc-uploads`,declinedObj).then((res)=>{
          if(res.data.status === 200)
          {
            toast.success('Uploads declined Successfull', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
          }else{
            toast.error('Unable to decline uploads', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
          }
        })
      }

      const onNotesChanges = async(notes) => {
        // console.log(notes)
        setDeclinedNotes(notes)
      }

      const getProducts = async() => {
       
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}all-products-unique`,{}).then((res)=>{
          setAllProducts(res.data.Products)
          console.log("unique products is ", res.data.Products)
        })
        

      }

      const handleProductSelection = (productId) => {
        console.log(productId)
        if (selectedProducts.includes(productId)) {
          setSelectedProducts(selectedProducts.filter(id => id !== productId));
        } else {
          setSelectedProducts([...selectedProducts, productId]);
        }
      };

      const submitProductSelection = async(userid) => {
        console.log(selectedProducts, userid, userCompany)
        var productObj = {
          userid: userid,
          company: userCompany,
          products: selectedProducts
      }
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-user-products`,productObj).then(async (res)=>{

          if(res.data.status == 200)
          {
            toast.success('User Products Updaeted', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
          }else{
            toast.error('Unable to update User products', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
          }

      })
      }

      const getSavedProducts = async(userid) => {
        var userObj = {
          id: userid
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}get-user-saved-products`,userObj).then((res)=>{

          console.log("saved settings is " + id, res.data.data)
          setSelectedProducts(res.data.data)
          // var receivedProducts = res.data.data
          // for (let product of receivedProducts)
          // {
          //   var productId = product.productid
          //   if (selectedProducts.includes(productId)) {
          //     setSelectedProducts(selectedProducts.filter(id => id !== productId));
          //   } else {
          //     setSelectedProducts([...selectedProducts, productId]);
          //   }
          // }
  
        })

        setAccountStatusLoading(false)
      }

  return (
    <React.Fragment>
              <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Profile" breadcrumbItem="Profile" />

        <Row>
            <Col md="8" sm="12">
              {personalLoading?
              <Spinner type="grow" className="ms-2" color="success" />
              :
            <Card>
                <CardBody>
                  <CardTitle className="mb-4">Personal Information</CardTitle>

                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Userid :</th>
                          <td>{typeof userInfo.userid !== undefined ? 
                          userInfo.userid 
                          : ''}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{typeof userInfo.lastname !== undefined ? 
                          userInfo.name + userInfo.lastname 
                          : ''}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{typeof userInfo.cell !== undefined  ? userInfo.cell : ''}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{typeof userInfo.email !== undefined  ? userInfo.email : ''}</td>
                        </tr>

                        {balanceLoading?
                        <Spinner type="grow" className="ms-2" color="success" />
                        :
                        <div>
                        <tr>
                          <th scope="row">Available Wallet Balance :</th>
              
                        </tr>

                        <tr>
                        <td>USDT {usdtWalletet.toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td>BTC {btcWalletet.toFixed(6)}</td>
                        </tr>

                        <tr>
                          <td>ZAR {zarWalletet.toFixed(2)}</td>
                        </tr>

                          <br />
                        <tr>
                          <th scope="row">Total In Products :</th>
                        </tr>

                        <tr>
                          <td>USDT {usdtProduct.toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td>ZAR {zarProduct.toFixed(2)}</td>
                        </tr>
                        </div>}

                      </tbody>
                    </Table>

                  </div>
                </CardBody>
              </Card>}

              {personalLoading?
              <Spinner type="grow" className="ms-2" color="success" />
              :
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Account Status</CardTitle>

                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Account Status :</th>
                          <td>{typeof userInfo.u_status !== undefined ? 
                          userInfo.u_status === 'A' ? 'Active' :  userInfo.u_status === 'P' ? 'FICA Required' : userInfo.u_status === 'L' ? 'Email Confirmation Required' : 'Locked'
                          : ''}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">FICA Status :</th>
                          <td>{typeof userInfo.fica_status !== undefined ? 
                          userInfo.fica_status === 'A' ? 'Approved' :  userInfo.fica_status === 'P' ? 'Waiting For Admin Approval' : 'FICA Required'
                          : ''}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">Auto Renew Products :</th>
                          <td>{typeof userInfo.autoRenew !== undefined ? 
                          userInfo.autoRenew ? 'Enabled' :  'Disabled'
                          : ''}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">Use Wallet Balance To AutoRenew :</th>
                          <td>{typeof userInfo.autoRenewBalance !== undefined ? 
                          userInfo.autoRenewBalance ? 'Enabled' :  'Disabled'
                          : ''}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">2FA :</th>
                          <td>{typeof userInfo.auth_secret !== undefined ? 
                          userInfo.auth_secret ? 'Enabled' :  'Disabled'
                          : ''}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">Bank Confirmation Status :</th>
                          <td>{typeof userProfileInfo.bank_status !== undefined ? 
                            userProfileInfo.bank_status
                          : ''}
                          </td>
                        </tr>

                        {userProfileInfo.bank_account_name ?
                        <div>

                          <tr>
                            <th scope="row">Bank Name :</th>
                            <td>{userProfileInfo.bank_bank_name}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Account Name :</th>
                            <td>{userProfileInfo.bank_account_name}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Account Number :</th>
                            <td>{userProfileInfo.bank_account_number}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Account Type :</th>
                            <td>{userProfileInfo.bank_account_type}
                            </td>
                          </tr>


                        </div>

                        : ''}

                      </tbody>
                    </Table>

                  </div>
                </CardBody>
              </Card>}

              {accountStatusLoading?
              <Spinner type="grow" className="ms-2" color="success" />
              :
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Products</CardTitle>

                  <div className="table-responsive">
                    {allProducts.map(product => (
                      <div key={product.productid}>
                        <input
                          type="checkbox"
                          disabled={adminUser === 'false' ? 'disabled' : ''}
                          id={product.productid}
                          checked={selectedProducts.includes(product.productid)}
                          onClick={() => handleProductSelection(product.productid)}
                        />
                        <label htmlFor={product.productid}>{product.name}</label>
                      </div>
                    ))}

                  </div>

                  <Button disabled={adminUser === 'false' ? 'disabled' : ''} onClick={(e) => {submitProductSelection(userInfo.userid)}} >Submit</Button>
                </CardBody>
              </Card>}

              {notificationsLoading?
              <Spinner type="grow" className="ms-2" color="success" />
              :
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Send Notification:</CardTitle>

                  <div className="table-responsive">
                    <label>Title:</label><br />
                    <input
                    className="form-control"
                    type="text"
                    value={notificationTitle} 
                    onChange={(e) => {changeNotificationTitle(e.target.value)}}
                    /><br />
                    <label>Description:</label><br />
                    <textarea
                    className="form-control"
                    value={notificationDescription} 
                    onChange={(e) => {changeNotificationDescription(e.target.value)}}
                    ></textarea><br />
                    <Button onClick={(e) => {sendNotification(userInfo.userid)}} >Submit</Button>


                  </div>
                </CardBody>
              </Card>}



            {userRole === 'super' ? 
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">KYC Information</CardTitle>

                  <div className="table-responsive">
                    <label>ID Number:</label><br />
                    <input
                    type="text"
                    className="form-control"
                    value={userProfileInfo.id_num} 
                    disabled
                    /><br />
                   <label>DOB:</label><br />
                    <input
                    type="text"
                    className="form-control"
                    value={userProfileInfo.dob} 
                    disabled
                    /><br />
                    <label>Address:</label><br />
                    <input
                    type="text"
                    className="form-control"
                    value={userProfileInfo.address} 
                    disabled
                    /><br />

                    <Table className="table-nowrap mb-0">
                      <tbody>

                        <tr>
                          <th scope="row">Account Status :</th>
                          <td>
                              <div className="mb-3">
                                <div className="col-md-10">
                                    <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setUStatus(e.target.value)}
                                        value={uStatus}
                                        // value={newCurrency}
                                    >
                                      {optionGroup.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select>

                                </div>
                              </div>
                            </td>
                        </tr>

                        <tr>
                          <th scope="row">KYC Status :</th>
                          <td>
                              <div className="mb-3">
                                <div className="col-md-10">

                                  <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setKYCStatus(e.target.value)}
                                        value={kycStatus}
                                        // value={newCurrency}
                                    >
                                      {ficaStatus.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select>
                                </div>
                              </div>
                            </td>
                        </tr>

                        <tr>
                          <th scope="row">Payment Status :</th>
                          <td>
                              <div className="mb-3">
                                <div className="col-md-10">


                                    <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setPayment(e.target.value)}
                                        value={payment}
                                        // value={newCurrency}
                                    >
                                      {paymentOption.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select>


                                </div>
                              </div>
                            </td>
                        </tr>

                        {/* <tr>
                          <th scope="row">VIP :</th>
                          <td>
                              <div className="mb-3">
                                <div className="col-md-10">

                                    <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setVIP(e.target.value)}
                                        value={vip}
                                        // value={newCurrency}
                                    >
                                      {vipOption.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select>


                                </div>
                              </div>
                            </td>
                        </tr> */}

                      </tbody>
                    </Table>

                            
                            <th scope="row">Uploads</th>
                            {/* const[declineIDUpload, setDeclineIDUpload] =  React.useState(false);
      const[declineAddressUpload, setDeclineAddressUpload] =  React.useState(false);
      const[declineCIPCCUpload, setDeclineCIPCCUpload] =  React.useState(false); */}

{declinedUploads ?
                          <div>
                          <p>Notes For Declining Uploads:</p>
                          <textarea value={declinedNotes} className="form-control" onChange={(e) => {onNotesChanges(e.target.value)}}>

                          </textarea>

                          <Button className="mt-4 mb-4 bg-success" onClick={(e) => {declineUploads(userInfo.userid)}} >Save</Button>
                          </div>
                          : ''}

                            <p>ID Upload:</p>
                            
                          {declineIDUpload ?
                            <p className="text-danger">Removed ID Upload</p>
                            :
                            <div>
                            <button value="id" onClick={(e) => {clickedDeclineButton("id")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                            {(userProfileInfo.idupload && !userProfileInfo.idupload.includes('.png') && !userProfileInfo.idupload.includes('.jpeg') && !userProfileInfo.idupload.includes('.jpg') ) ?
                          // <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_BASE + 'assets/'+userProfileInfo.idupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                          <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.idupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                          :
                         <Zoom><img src={idupload} className="w-25 img-thumbnail"/></Zoom>}

                         <div className="mt-2">
                          {
                            // console.log("the user profile we are referring to is: ")
                          (userProfileInfo.idupload2 && !userProfileInfo.idupload2.includes('.png') && !userProfileInfo.idupload2.includes('.jpeg') && !userProfileInfo.idupload2.includes('.jpg') ) ?
                            // <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_BASE + 'assets/'+userProfileInfo.idupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                            <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.idupload2} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                            :
                          <Zoom><img src={idupload2} className="w-25 img-thumbnail"/></Zoom>}
                          </div>
                          </div>
                        }
                        
                         <p>Proof Of Address Upload:</p>
                         {declineAddressUpload ?
                            <p className="text-danger">Removed Proof Of Address Upload</p>
                            :
                            <div>
                         <button value="poa" onClick={(e) => {clickedDeclineButton("poa")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                         {(userProfileInfo.poaupload && !userProfileInfo.poaupload.includes('.png') && !userProfileInfo.poaupload.includes('.jpeg') && !userProfileInfo.poaupload.includes('.jpg') )?
                          <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.poaupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                         :
                         <Zoom><img src={poaupload} className="w-25 img-thumbnail"/></Zoom> }
                                </div>
                          }

      

                          

                          {userInfo.usertype == '1' || userInfo.usertype == 1?
                          <div>
                          <p>CIPC:</p>

                          {declineCIPCCUpload ?
                            <p className="text-danger">Removed CIPCC Upload</p>
                            :
                            <div>
                          <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                          {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                          <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                         :
                         <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> }
                         </div>
                          }

                

                          <p>CIPC Nr: {cipc}</p>
                          </div>
                          : ''}
                        <br />
  
                    <Button className="mt-4" onClick={(e) => {approveKYC(userInfo.userid)}} >Submit</Button>


                  </div>
                </CardBody>
              </Card>
              : ''}
              </Col>

             
              <Col xl="4" sm="6">
              {!displayUserSpinner ?
                <div>

              <div className="mt-2">
                <a href={"../user-deposits/" + userInfo.userid}>
                        <Button><h5 className="font-size-15 text-white ">
                            Deposits
                        </h5></Button>
                </a>
                </div>

                <div className="mt-2">
                <a href={"../user-withdrawals/" + userInfo.userid}>
                    <Button><h5 className="font-size-15 text-white ">
                            Withdrawals
                        </h5></Button>
                </a>
                </div>

                <div className="mt-2">
                <a href={"../user-products/" + userInfo.userid}>
                    <Button><h5 className="font-size-15 text-white  ">
                            Products
                        </h5></Button>
                </a>
                </div>

                <div className="mt-2">
                <a href={"../user-transactions/" + userInfo.userid}>
                    <Button><h5 className="font-size-15 text-white  ">
                            Transactions
                        </h5></Button>
                </a>
                </div>
                </div>
                : 
                <Spinner type="grow" className="ms-2" color="success" />
                }
                </Col>


        </Row>
        </Container>
      </div>
    </React.Fragment>

  )
}

export default EditUser