import React from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

import ApexRadial from "./ApexRadial"

const AdminDashboardDisplay = ({monthlyDataTotal}) => {
  return (
    <React.Fragment>
      {" "}

    </React.Fragment>
  )
}

export default AdminDashboardDisplay
