import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Spinner
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../Dashboard/LatestTranactionCol";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";

import { CSVLink, CSVDownload } from "react-csv";

import PDFProducts from "./PDFProducts";
import { PDFDownloadLink } from '@react-pdf/renderer'

function products() {
    
    const[tableData, setTableData] =  React.useState([]);
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('User Products');

    const[name, setName] =  React.useState('');
  
    const[useDate, setUseDate] =  React.useState('');
    const[totalZAR, setTotalZAR] =  React.useState(0);
    const[totalUSDT, setTotalUSDT] =  React.useState(0);
    const[totalBTC, setTotalBTC] =  React.useState(0);
    const [productData, setProductData] = useState([])
    const [checkedData, setCheckedData] = useState([])
    const[displayUserSpinner, setDisplayUserSpinner] =  React.useState(true);

    const productname = {
        '15':   "Accumulation Fund",
        '16':   "Savings Vault",
        '17':   "Savings Vault",
        '18':   "Inflation Fund Index",
        '19':   "Inflation Fund Index",
        '20':   "Inflation Fund Index",
    }

    const columns = useMemo(
        () => [
          {
            Header: "Plan ID",
            accessor: "planid",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Userid",
            accessor: "userid",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Transaction ID",
            accessor: "transaction_id",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return <Date {...cellProps} />;
            },
          },
          {
            Header: "Amount",
            accessor: "amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return <Total {...cellProps} />;
            },
          },
          {
            Header: "Status",
            accessor: "status",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return             <p
    
              >
                {cellProps.value == '0' ? 'Pending' : cellProps.value == '1' ? 'Open' : cellProps.value == '3' ? 'Closed' : 'Declined'}
              </p>
            },
          },
          {
            Header: "Invest Date",
            accessor: "invest_date",
            disableFilters: true,
            Cell: cellProps => {
              return <PaymentMethod {...cellProps} />;
            },
          },
          {
            Header: "Interests",
            accessor: "interests",
            disableFilters: true,
            Cell: cellProps => {
               return <div> { console.log(cellProps.value[0])
               }
                {typeof cellProps.value !== 'undefined' && cellProps.value.length > 0  ?
              
              Object.keys(cellProps.value).map(function(key) {
            
               <div key={key}><p>{key.amount}</p>
               <p>{key.percentage}</p></div>   })
               : ''}</div>
              
            },
          },
          {
            Header: "Currency",
            accessor: "currency",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value == '1' ? 'USDT' : cellProps.value == '2' ? 'ZAR' : 'BTC'}
                </p>
              );
            },
            // Header: "View Details",
            // disableFilters: true,
            // accessor: "view",
            // Cell: cellProps => {
            //   return (
            //     <Button
            //       type="button"
            //       color="primary"
            //       className="btn-sm btn-rounded"
            //       onClick={toggleViewModal}
            //     >
            //       View Details
            //     </Button>
            //   );
            // },
          },
        ],
        []
      );

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            getUserDeposits(userid)
            getUserProductData(userid)
            setDisplayUserSpinner(false);
          }
        }
      }, [auth])
    
    
      const getUserDeposits = async (userid) => {
    
        const userData = {
          userid: userid,
        }
    
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/find-user-products`,userData).then((res)=>{
    
          if(res.data.status == 200)
          {
            console.log(res.data.data)
            setTableData(res.data.data)
            
          }
    
        })
      }

      const getUserProductData = async (userid) => {
        
        var userObj = {
            userid: userid
          }
      
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}/all-user-open-products`,userObj).then(async (res)=>{
            if(res.data.status == 200)
            {
              console.log(res.data)
              setProductData(res.data.total)
            }else{
              console.log(res.data)
            }
          })
  
          return true;
      }

  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="Profile" breadcrumbItem="Profile" />

    {/* <Row>
    <Col md="12" sm="12">
        <UserSingleTable tableData={tableData} columns={columns} title={title} />
        </Col>
        </Row> */}


        <Row>
            <Col lg={12}>
            {!displayUserSpinner ?
                <div>
              <Card>
                <CardBody>
                <PDFDownloadLink document={<PDFProducts title="Product Statement" name={name}  info={productData} userid={userid} date={useDate} />} fileName={"Products-" + useDate} >
                  {({loading }) => (loading ? "Loading..." : <button className="btn btn-primary rounder border-2" type="button">
                  Download PDF Statement
                  </button>)}
              </PDFDownloadLink>
                {tableData.map((transaction,key) => (
                  <div key={key} className="table-responsive">
                    <Table  className="table mb-0">
                      <thead>
                        <tr>
                          <th>Plan ID</th>
                          <th>Transaction ID</th>
                          <th>Contribution Amount</th>
                          <th>Status</th>
                          <th>Invest Date</th>
                          {/* <th>Expire Date</th> */}
                          
                          <th>Interests</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">{transaction.planid}</th>
                          <td>{transaction.transaction_id}</td>
                          <td>{transaction.currency == '1' ? 'USDT' : transaction.currency == '2' ? 'ZAR' : 'BTC'} {transaction.amount}</td>
                          <td>{transaction.status == '0' ? 'Pending' : transaction.status == '1' ? 'Open' : transaction.status == '3' ? 'Closed' : 'Declined'}</td>
                          <td>{transaction.invest_date}</td>
                          {/* <td></td> */}
                          <td>
                          {typeof transaction.interests !== undefined && transaction.interests.length > 0 ?
                          Object.keys(transaction.interests[0]).map((interest,key) => (
                          
                            <div key={key}>
                     {/* <div>{console.log('interests 1 ', transaction.interests[0][interest].amount)}</div> */}
                            <p>Month - {interest.split("_")[1]}</p>
                            <p >Amount - {transaction.interests[0][interest].amount}</p>
                            <p>Percentage - { transaction.interests[0][interest].persentage}</p>
                            </div>
                          ))
                          : 'No Interest Available'
                          }
                          </td>
                        </tr>

                      </tbody>
                    </Table>
                  </div>
                       ))}
                </CardBody>
              </Card>
              </div>
        :
        <Spinner type="grow" className="ms-2" color="success" />
        }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default products

